<template>
  <DetailTemplate
    :customClass="'quotation-detail detail-page'"
    v-if="getPermission('quotation:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ detail.quotation_title }}
          </h1>
          <CustomStatus
            :status="detail.status"
            endpoint="quotation/status"
          ></CustomStatus>
          <v-chip
            v-if="detail.variation_level > 0"
            label
            color="green white--text"
            class="text-uppercase font-weight-600"
          >
            Pre Quotation
          </v-chip>
          <v-chip
            v-if="detail.parent_variation > 0"
            label
            color="orange white--text"
            class="text-uppercase font-weight-600"
          >
            Variation
          </v-chip>
          <v-chip
            v-if="detail.is_package"
            label
            color="green white--text"
            class="text-uppercase font-weight-600"
          >
            Package
          </v-chip>
          <template v-if="false">
            <template v-if="detail.is_revised">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    color="red lighten-4"
                    class="ml-0 mr-2 black--text font-weight-600"
                    label
                  >
                    REVISED
                  </v-chip>
                </template>
                <span>{{ get_page_title() }} is Revised</span>
              </v-tooltip>
            </template>
            <template v-if="lodash.isEmpty(detail.revise) === false">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    color="teal lighten-4"
                    class="ml-0 mr-2 black--text font-weight-600"
                    label
                    link
                    v-on:click="
                      $router.push(
                        getDefaultRoute('quotation.detail', {
                          params: {
                            id: detail.revise.id,
                          },
                        })
                      )
                    "
                  >
                    REVISION
                  </v-chip>
                </template>
                <span>Revision of {{ detail.revise.barcode }}</span>
              </v-tooltip>
            </template>
          </template>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="detail.status == 1">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('edit')"
            >
              <v-icon left>mdi-pencil</v-icon> Edit
            </v-btn>
          </template>
          <span>Click here to Edit</span>
        </v-tooltip>
      </template>
      <template v-if="has_case_trust && detail.is_accepted">
        <v-tooltip v-if="false" top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('upload_case_trust')"
            >
              <v-icon left>mdi-upload</v-icon> CaseTrust
            </v-btn>
          </template>
          <span>Upload CaseTrust</span>
        </v-tooltip>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('quotation')"
            >
              <v-icon left>mdi-update</v-icon> Quotation
            </v-btn>
          </template>
          <span>Convert to Quotation</span>
        </v-tooltip>
      </template>
      <template v-if="isPackage && detail.is_accepted && !has_case_trust">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="case_trust_dialog = true"
            >
              <v-icon left>mdi-book-lock-open-outline</v-icon> Case Trust
            </v-btn>
          </template>
          <span>Generate Case Trust</span>
        </v-tooltip>
      </template>
      <template v-if="detail.is_accepted">
        <template v-if="isQuotation">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!formValid || formLoading || pageLoading"
                :loading="formLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('variation')"
              >
                <v-icon left>mdi-update</v-icon> Variation Order
              </v-btn>
            </template>
            <span>Create variation order</span>
          </v-tooltip>
        </template>
      </template>
      <template v-else-if="false && detail.status == 1">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('edit')"
            >
              <v-icon left>mdi-pencil</v-icon> Edit
            </v-btn>
          </template>
          <span>Edit {{ get_page_title() }}</span>
        </v-tooltip>
      </template>
      <template v-if="false">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('revise')"
            >
              <v-icon left>mdi-update</v-icon> Revise
            </v-btn>
          </template>
          <span>Click here to revise {{ get_page_title() }}</span>
        </v-tooltip>
      </template>
      <template v-if="moreActions.length < 3">
        <v-tooltip
          top
          content-class="custom-top-tooltip"
          v-for="(more, index) in moreActions"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :loading="formLoading"
              :disabled="more.disabled"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction(more.action)"
            >
              <v-icon small>{{ more.icon }}</v-icon> {{ more.title }}
            </v-btn>
          </template>
          <span>Click here to {{ more.title }}</span>
        </v-tooltip>
      </template>
      <v-menu
        v-else
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container v-bar class="detail-quotation-vbar" fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container fluid>
              <v-list flat>
                <v-layout>
                  <template v-if="!lodash.isEmpty(customer)">
                    <v-flex class="mr-2" md6>
                      <label class="font-size-18 font-weight-600"
                        >Customer&nbsp;&nbsp;&nbsp;<v-icon
                          v-on:click="
                            $router.push(
                              getDefaultRoute('customer.detail', {
                                params: {
                                  id: customer.id,
                                },
                              })
                            )
                          "
                          class="color-custom-blue"
                          >mdi-link mdi-rotate-135</v-icon
                        ></label
                      >
                      <p class="font-size-18 m-0" v-if="customer.barcode">
                        <Barcode
                          small
                          route="customer.detail"
                          :barcode="customer.barcode"
                          :id="customer.id"
                        ></Barcode>
                      </p>
                      <p class="font-size-18 m-0" v-if="customer.display_name">
                        {{ customer.display_name }}
                      </p>
                      <p class="font-size-18 m-0" v-if="customer.company_name">
                        {{ customer.company_name }}
                      </p>
                    </v-flex>
                  </template>
                  <template v-if="!lodash.isEmpty(billing)">
                    <v-flex class="ml-2" md6>
                      <label class="font-size-18 font-weight-600"
                        >Site Address&nbsp;&nbsp;&nbsp;<v-icon
                          v-if="false"
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        ></label
                      >
                      <p class="font-size-18 m-0" v-if="billing.unit_no">
                        {{ billing.unit_no }}
                      </p>
                      <p class="font-size-18 m-0" v-if="billing.street_1">
                        {{ billing.street_1 }}
                      </p>
                      <p class="font-size-18 m-0" v-if="billing.street_2">
                        {{ billing.street_2 }}
                      </p>
                      <p class="font-size-18 m-0" v-if="billing.zip_code">
                        {{ billing.zip_code }}
                      </p>
                      <p class="font-size-18 m-0" v-if="billing.country">
                        {{ billing.country }}
                      </p>
                    </v-flex>
                  </template>
                </v-layout>
                <br />
                <v-layout>
                  <template v-if="!lodash.isEmpty(contact_person)">
                    <v-flex class="mr-2" md6>
                      <label class="font-size-18 font-weight-600"
                        >Contact Person&nbsp;&nbsp;&nbsp;<v-icon
                          v-if="false"
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        ></label
                      >
                      <p class="font-size-18 m-0">
                        {{ contact_person.full_name }}
                      </p>
                      <p class="font-size-18 m-0">
                        {{ contact_person.primary_phone }}
                      </p>
                      <p class="font-size-18 m-0">
                        {{ contact_person.primary_email }}
                      </p>
                    </v-flex>
                  </template>
                  <template v-if="!lodash.isEmpty(new_contact_person)">
                    <v-flex class="ml-2" md6>
                      <label class="font-size-18 font-weight-600"
                        >Contact Person&nbsp;&nbsp;&nbsp;<v-icon
                          v-if="false"
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        ></label
                      >
                      <p class="font-size-18 m-0">
                        {{ new_contact_person.full_name }}
                      </p>
                      <p class="font-size-18 m-0">
                        {{ new_contact_person.primary_phone }}
                      </p>
                      <p class="font-size-18 m-0">
                        {{ new_contact_person.primary_email }}
                      </p>
                    </v-flex>
                  </template>
                </v-layout>
              </v-list>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <p
                    v-else
                    class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                    v-on:dblclick="updateField('quotation_title')"
                  >
                    <template v-if="updateDetailDialog.quotation_title">
                      <v-text-field
                        ref="quotation_title"
                        v-model.trim="updateDetail.quotation_title"
                        dense
                        filled
                        color="cyan"
                        :label="`${get_page_title()} Title`"
                        :rules="[
                          validateRules.minLength(
                            updateDetail.quotation_title,
                            `${get_page_title()} Title`,
                            1
                          ),
                          validateRules.maxLength(
                            updateDetail.quotation_title,
                            `${get_page_title()} Title`,
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :loading="particuleFieldLoading"
                        :disabled="particuleFieldLoading"
                        @keydown.esc="onEscParticularField"
                        @keydown.enter="updateParticularField"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <span
                        :class="{
                          'custom-bottom-border': canUpdateQuotation,
                        }"
                        >{{ detail.quotation_title }}</span
                      >
                      <UpdateTooltip v-if="canUpdateQuotation"></UpdateTooltip>
                    </template>
                  </p>
                </v-col>
                <v-col md="12">
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100" style="table-layout: fixed">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="40%">
                          {{ get_page_title() }} #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 pt-3 pb-1"
                          width="60%"
                        >
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr v-if="quote_package">
                        <td class="font-size-18 py-1" width="40%">Package #</td>
                        <td
                          class="font-weight-600 font-size-18 py-1"
                          width="60%"
                        >
                          <span
                            class="color-custom-blue cursor-pointer"
                            v-on:click="push_to_package()"
                            >{{ quote_package.barcode }}</span
                          >
                        </td>
                      </tr>
                      <tr v-if="project && project.id">
                        <td class="font-size-18 py-1" width="40%">Project #</td>
                        <td
                          class="font-weight-600 font-size-18 py-1"
                          width="60%"
                        >
                          <v-layout>
                            <v-flex
                              ><v-chip
                                small
                                outlined
                                label
                                v-on:click="routeToProject()"
                                color="chip-custom-blue"
                                class="cursor-pointer font-weight-600 font-size-18"
                                >{{ project.barcode }}</v-chip
                              ></v-flex
                            >
                            <v-flex style="max-width: 450px"
                              ><p
                                class="ml-2 font-weight-600 font-size-18 text-truncate mb-0"
                              >
                                {{ project.name }}
                              </p></v-flex
                            >
                          </v-layout>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td class="font-size-18 pb-1" width="40%">
                          Rate opportunity
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 pb-1"
                          v-on:dblclick="updateField('rating')"
                        >
                          <template v-if="updateDetailDialog.rating">
                            <v-rating
                              ref="rating"
                              class="remove-y-padding"
                              v-model.trim="updateDetail.rating"
                              background-color="orange lighten-3"
                              color="orange"
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @input="updateParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-rating>
                          </template>
                          <template v-else>
                            <span class="d-inline-block"
                              ><v-rating
                                readonly
                                class="remove-y-padding"
                                v-model.trim="detail.rating"
                                background-color="orange lighten-3"
                                color="orange"
                              ></v-rating
                            ></span>
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="40%">
                          Reference #
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('reference')"
                        >
                          <template v-if="updateDetailDialog.reference">
                            <v-text-field
                              ref="reference"
                              v-model.trim="updateDetail.reference"
                              dense
                              filled
                              color="cyan"
                              label="Reference #"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.reference,
                                  'Reference',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.reference,
                                  'Reference',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ detail.reference }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td class="font-size-18 py-1" width="40%">
                          Sales representative
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="updateDetailDialog.sales">
                            <v-text-field
                              ref="sales"
                              v-model.trim="updateDetail.sales"
                              dense
                              filled
                              color="cyan"
                              label="Sales representative"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.sales,
                                  'Sales representative',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.sales,
                                  'Sales representative',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ detail.sales }}</span
                            >
                            <UpdateTooltip
                              v-if="false && canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="40%">
                          Prepared by
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('prepared_by')"
                        >
                          <template v-if="updateDetailDialog.prepared_by">
                            <v-text-field
                              ref="prepared_by"
                              v-model.trim="updateDetail.prepared_by"
                              dense
                              filled
                              color="cyan"
                              label="Prepared by"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.prepared_by,
                                  'Prepared by',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.prepared_by,
                                  'Prepared by',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ detail.prepared_by }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr v-if="detail.assistant_relation">
                        <td class="font-size-18 py-1" width="40%">
                          CO-Designer
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ detail.assistant_relation.full_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="40%">
                          {{ get_page_title() }} Amount
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ formatMoneyTwoDecimal(detail.total) }}
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style="table-layout: fixed">
                      <tr class="custom-border-top">
                        <td
                          class="font-size-18 py-1 custom-border-right pr-4"
                          width="200"
                        >
                          Created date
                        </td>
                        <td
                          v-if="detail.sent_date"
                          class="font-size-18 px-4 py-1 custom-border-right"
                          width="200"
                        >
                          Sent date
                        </td>
                        <td class="font-size-18 px-4 py-1" width="200">
                          Open till
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
                          v-on:dblclick="updateField('date')"
                        >
                          <template v-if="updateDetailDialog.date">
                            <v-menu
                              v-model="createdDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="date"
                                  dense
                                  filled
                                  flat
                                  label="Date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="getFormattedDate(updateDetail.date)"
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="createdDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ formatDate(detail.date) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          v-if="detail.sent_date"
                          class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
                          v-on:dblclick="updateField('sent_date')"
                        >
                          <template v-if="updateDetailDialog.sent_date">
                            <v-menu
                              v-model="sentDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="sent_date"
                                  dense
                                  filled
                                  flat
                                  label="Sent date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.sent_date)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.sent_date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="sentDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ formatDate(detail.sent_date) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 px-4"
                          v-on:dblclick="updateField('open_till')"
                        >
                          <template v-if="updateDetailDialog.open_till">
                            <v-menu
                              v-model="openTillPicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="open_till"
                                  dense
                                  filled
                                  flat
                                  label="Open till"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.open_till)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.open_till"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="openTillPicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ formatDate(detail.open_till) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="quotationTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#other"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/quotation.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#attachment"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/attachment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Documents
              </v-tab>
              <v-tab
                v-if="false"
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#attachment"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/attachment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Documents
              </v-tab>
              <template v-if="getPermission('line-item:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.line_items_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.line_items_count"
                  >
                    {{
                      detail.line_items_count > 1 ? "Line Item" : "Line Item"
                    }}
                  </v-badge>
                  <template v-else> Line Item </template>
                </v-tab>
              </template>
              <v-tab
                v-if="false"
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#quote-files"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/attachment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="related_files.length"
                  bordered
                  color="orange darken-4"
                  :content="related_files.length"
                >
                  Documents
                </v-badge>
                <template v-else> Documents </template>
              </v-tab>
              <v-tab
                v-if="isQuotation"
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#child-quotation"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/history.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="variation_orders.length"
                  bordered
                  color="orange darken-4"
                  :content="variation_orders.length"
                >
                  Quotation
                </v-badge>
                <template v-else> Quotation </template>
              </v-tab>
              <v-tab
                v-if="isPackage"
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#package-quotations"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/quotation.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="package_quotation.length"
                  bordered
                  color="orange darken-4"
                  :content="package_quotation.length"
                >
                  Quotations
                </v-badge>
                <template v-else> Quotations </template>
              </v-tab>
              <template v-if="getPermission('history:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="quotationTab">
              <v-tab-item value="other">
                <v-container fluid class="tab-container">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Description
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="detail.description || '<em>No Description</em>'"
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <template v-if="false">
                    <br />
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Terms &amp; Conditions
                        </h3>
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                  </template>
                  <br />
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Internal Notes &amp; Attachments
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-layout>
                        <v-flex md6 class="custom-grey-border mr-4 p-4">
                          <label class="font-size-16 font-weight-600"
                            >Internal Note</label
                          >
                          <read-more
                            class="custom-read-more custom-border-top pt-2 font-weight-600 font-size-16 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.admin_remark || '<em>No Internal Note</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                        <v-flex md6 class="custom-grey-border p-4">
                          <label class="font-size-16 font-weight-600"
                            >Client Note
                            <span class="text--secondary font-weight-500"
                              >(visible on PDF)</span
                            ></label
                          >
                          <read-more
                            class="custom-read-more custom-border-top pt-2 font-weight-600 font-size-16 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.client_remark || '<em>No Client Note</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="false">
                        <v-flex
                          v-if="false"
                          md6
                          class="custom-grey-border mr-4 p-4"
                        >
                          <label class="font-size-16 font-weight-600"
                            >Client Notes</label
                          >
                          <read-more
                            class="custom-read-more custom-border-top pt-2 font-weight-600 font-size-16 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.client_remark || '<em>No Client Notes</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                        <v-flex md12 class="custom-grey-border p-4">
                          <label class="font-size-16 font-weight-600"
                            >Admin Notes</label
                          >
                          <read-more
                            class="custom-read-more custom-border-top pt-2 font-weight-600 font-size-16 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.admin_remark || '<em>No Admin Notes</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                      </v-layout>
                      <br />
                      <v-layout>
                        <v-flex md6 class="custom-grey-border p-4 mr-4">
                          <label class="font-size-16 font-weight-600"
                            >Attachments</label
                          >
                          <div class="custom-border-top pt-2 w-100">
                            <table width="100%" v-if="attachments.length">
                              <tr
                                v-for="(row, index) in attachments"
                                :key="index"
                              >
                                <td height="30px">{{ row.name }}</td>
                                <td height="30px">
                                  <v-icon
                                    v-on:click="download_file(row)"
                                    color="cyan"
                                    >mdi-download</v-icon
                                  >
                                </td>
                                <td height="30px">
                                  <v-icon
                                    v-on:click="delete_file_dialog(row)"
                                    color="red lighten-1"
                                    >mdi-delete</v-icon
                                  >
                                </td>
                              </tr>
                            </table>
                            <em v-else>No Attachments</em>
                          </div>
                        </v-flex>
                        <v-flex md6 class="ml-4 p-4"> </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item value="attachment">
                <RelatedDocument
                  quotation
                  v-if="detail.id"
                  :quotationId="detail.id"
                ></RelatedDocument>
              </v-tab-item>
              <template v-if="getPermission('line-item:view')">
                <v-tab-item value="line-item">
                  <v-container>
                    <LineItemPackageDetail
                      v-if="packages.length"
                      v-model="packages"
                      item-title="Package"
                    ></LineItemPackageDetail>
                    <br />
                    <LineItemNewDetailV1
                      v-if="!isPackage && services.length"
                      v-model="services"
                      item-title="Additional Works"
                    ></LineItemNewDetailV1>
                    <br />
                    <v-row>
                      <v-col md="6">
                        <table width="100%" style="table-layout: fixed">
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Supplier Cost</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(
                                        detail.supplier_cost
                                      )
                                    }}</label
                                  >
                                </template>
                                <span
                                  >Sum of All Line Item Total Supplier
                                  Cost</span
                                >
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Profit</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(detail.profit)
                                    }}</label
                                  >
                                </template>
                                <span>Sub Total - Total Supplier Cost</span>
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Quotation Mark Up (in %)</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatNumber(
                                        detail.quotation_markup
                                      )
                                    }}%</label
                                  >
                                </template>
                                <span
                                  >(Sub Total / Total Supplier Cost) - 1</span
                                >
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Quotation Profit (in %)</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatNumber(
                                        detail.quotation_profit
                                      )
                                    }}%</label
                                  >
                                </template>
                                <span>Total Profit / Sub Total</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col md="6">
                        <table width="100%">
                          <tr>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Sub Total</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.sub_total)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="!isPackage && detail.optional_total">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Optional Total</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.optional_total)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="false">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Optional (with sub total)</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(
                                  detail.optional_total + detail.sub_total
                                )
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.discount_amount">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Discount
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.discount_amount)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.discount_two_amount">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Discount
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.discount_two_amount)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="false && detail.tax_value">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Tax [{{ formatNumber(detail.tax_value) }}%]
                                (without discount)</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="cursor-pointer font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(detail.sub_total)
                                    }}</label
                                  >
                                </template>
                                <span class="text-capitalize"
                                  >Taxable Amount</span
                                >
                              </v-tooltip>
                            </td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(
                                  detail.tax_without_discount
                                )
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.tax_value">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Tax [{{ formatNumber(detail.tax_value) }}%]
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="cursor-pointer font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(
                                        detail.taxable_amount
                                      )
                                    }}</label
                                  >
                                </template>
                                <span class="text-capitalize"
                                  >Taxable Amount</span
                                >
                              </v-tooltip>
                            </td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.tax_amount)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.adjustment">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Adjustment</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.adjustment)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="false">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Grand Total (without discount)</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(
                                  detail.total_without_discount
                                )
                              }}</label>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Grand Total
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.total)
                              }}</label>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </template>
              <v-tab-item v-if="isPackage" value="quote-files">
                <div>
                  <v-layout class="align-center">
                    <v-flex md6 class="px-2">
                      <label class="font-size-18 font-weight-600 mb-0"
                        >Documents</label
                      >
                    </v-flex>
                    <v-flex md6 class="text-right my-2">
                      <v-btn
                        v-if="file_checkbox.length > 1"
                        class="mr-2 custom-bold-button"
                        color="cyan white--text"
                        v-on:click="open_merge_dialog()"
                      >
                        <v-icon>mdi-table-merge-cells</v-icon> Merge Documents
                      </v-btn>
                      <v-btn
                        v-on:click="upload_dialog = true"
                        class="ml-2 custom-bold-button"
                        color="cyan white--text"
                      >
                        <v-icon>mdi-upload</v-icon> Upload Document
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <div class="custom-listing-table">
                    <v-simple-table class="simple-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th width="10%" class="simple-table-th">#</th>
                            <th width="50%" class="simple-table-th">Name</th>
                            <th
                              v-if="false"
                              width="40%"
                              class="simple-table-th"
                            >
                              Description
                            </th>
                            <th width="20%" class="simple-table-th">Type</th>
                            <th width="20%" class="simple-table-th">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(row, index) in related_files"
                            :key="index"
                          >
                            <td
                              width="10%"
                              class="simple-table-td"
                              valign="middle"
                            >
                              <v-checkbox
                                v-model="file_checkbox"
                                :value="row.id"
                                class="my-0 py-0 mx-0 px-0"
                                color="cyan"
                                hide-details
                              ></v-checkbox>
                            </td>
                            <td width="50%" class="simple-table-td">
                              {{ row.name }}
                            </td>
                            <td
                              v-if="false"
                              width="40%"
                              class="simple-table-td"
                            >
                              {{ row.description }}
                            </td>
                            <td width="20%" class="simple-table-td">
                              {{ get_related_type(row.type) }}
                            </td>
                            <td width="20%" class="simple-table-td">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click="
                                      download_related_pdf(row, 'raw_path')
                                    "
                                    :disabled="!row.raw_path"
                                    class="mr-2"
                                    color="blue darken-4"
                                    icon
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </template>
                                <span class="text-capitalize"
                                  >Download {{ row.name }}</span
                                >
                              </v-tooltip>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click="
                                      download_related_pdf(row, 'signed_path')
                                    "
                                    :disabled="!row.signed_path"
                                    class="mr-2"
                                    color="blue darken-4"
                                    icon
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </template>
                                <span class="text-capitalize"
                                  >Download Signed {{ row.name }}</span
                                >
                              </v-tooltip>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click="upload_related_pdf(row)"
                                    class="mr-2"
                                    color="blue darken-4"
                                    icon
                                  >
                                    <v-icon>mdi-upload</v-icon>
                                  </v-btn>
                                </template>
                                <span class="text-capitalize"
                                  >Upload Signed {{ row.name }}</span
                                >
                              </v-tooltip>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click="view_related_pdf(row)"
                                    :disabled="!row.is_pdf"
                                    class="mr-2"
                                    color="blue darken-4"
                                    icon
                                  >
                                    <v-icon>mdi-file-pdf</v-icon>
                                  </v-btn>
                                </template>
                                <span class="text-capitalize"
                                  >View {{ row.name }}</span
                                >
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item v-if="isPackage" value="package-quotations">
                <v-container class="tab-container">
                  <div class="custom-listing-table">
                    <v-simple-table class="simple-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="simple-table-th">Quotation #</th>
                            <th class="simple-table-th">
                              Quotation Title / Site Address
                            </th>
                            <th class="simple-table-th">Basic Details</th>
                            <th class="simple-table-th">Dates</th>
                            <th class="simple-table-th">Status</th>
                            <th class="simple-table-th">Amount</th>
                            <th class="simple-table-th">Created Time</th>
                          </tr>
                        </thead>
                        <tbody v-if="package_quotation.length">
                          <tr
                            v-for="(data, index) in package_quotation"
                            :key="index"
                            v-on:click="routeToDetail(data)"
                          >
                            <td class="simple-table-td">
                              <Barcode :barcode="data.barcode"></Barcode>
                            </td>
                            <td class="simple-table-td">
                              <div class="quotation-listing-customer">
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Quotation Title: </b
                                  >{{ data.quotation_title }}
                                </p>
                                <p
                                  class="m-0 custom-nowrap-ellipsis-two-line"
                                  v-if="data.billing"
                                >
                                  <b>Site Address: </b>
                                  <template v-if="data.billing.property_address"
                                    >{{ data.billing.property_address }},
                                  </template>
                                </p>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <div class="quotation-listing-customer">
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Reference: </b>
                                  <template v-if="data.reference">{{
                                    data.reference
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Reference</em
                                    ></template
                                  >
                                </p>
                                <p
                                  v-if="false"
                                  class="m-0 custom-nowrap-ellipsis"
                                >
                                  <b>Sales representative: </b>
                                  <template v-if="data.sales">{{
                                    data.sales
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Sales representative</em
                                    ></template
                                  >
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Prepared by: </b>
                                  <template v-if="data.prepared_by">{{
                                    data.prepared_by
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Prepared by</em
                                    ></template
                                  >
                                </p>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.date"
                              >
                                <b>Created: </b> {{ formatDate(data.date) }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.open_till"
                              >
                                <b>Open Till: </b>
                                {{ formatDate(data.open_till) }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.sent_date"
                              >
                                <b>Sent: </b> {{ formatDate(data.sent_date) }}
                              </p>
                            </td>
                            <td class="simple-table-td">
                              <CustomStatus
                                small
                                :status="data.status"
                                endpoint="quotation/status"
                              ></CustomStatus>
                            </td>
                            <td class="simple-table-td">
                              <div class="quotation-listing-amount">
                                <p
                                  v-if="false"
                                  class="m-0 custom-nowrap-ellipsis"
                                >
                                  <b>Profit: </b>
                                  {{
                                    $accountingJS.formatMoneyTwoDecimal(
                                      data.total_profit
                                    )
                                  }}
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Total: </b>
                                  {{
                                    $accountingJS.formatMoneyTwoDecimal(
                                      data.total
                                    )
                                  }}
                                </p>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <TableActivity
                                v-if="!lodash.isEmpty(data.added_by)"
                                :data="data"
                              >
                                <template v-slot:display_name>
                                  {{ data.added_by.display_name }}
                                </template>
                                <template v-slot:date_time>
                                  {{ data.created_at }}
                                </template>
                                <template v-slot:format_date_time>
                                  {{ formatDateTime(data.added_at) }}
                                </template>
                              </TableActivity>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot v-else>
                          <tr>
                            <td colspan="7">
                              <p
                                class="m-0 row-not-found text-center font-weight-500 font-size-16"
                              >
                                <img
                                  :src="$assetURL('media/error/empty.png')"
                                  class="row-not-found-image"
                                />
                                Uhh... There are no quotation at the moment.
                              </p>
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item v-if="isQuotation" value="child-quotation">
                <v-container class="tab-container">
                  <div class="custom-listing-table">
                    <v-simple-table class="simple-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="simple-table-th">
                              {{ get_page_title() }} #
                            </th>
                            <th class="simple-table-th">
                              {{ get_page_title() }} Title / Site Address
                            </th>
                            <th class="simple-table-th">Basic Details</th>
                            <th class="simple-table-th">Dates</th>
                            <th class="simple-table-th">Status</th>
                            <th class="simple-table-th">Amount</th>
                            <th class="simple-table-th">Created Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in variation_orders"
                            :key="index"
                            v-on:click="
                              routeToDetail('pre.quotation.detail', data)
                            "
                          >
                            <td class="simple-table-td">
                              <div>
                                <Barcode :barcode="data.barcode"></Barcode>
                              </div>
                              <div v-if="data.previous_vo">
                                <v-chip
                                  small
                                  label
                                  color="brown white--text"
                                  class="font-size-12 text-uppercase font-weight-600"
                                >
                                  Previous Variation
                                </v-chip>
                              </div>
                              <div v-else-if="data.pre_quote">
                                <v-chip
                                  small
                                  label
                                  color="green white--text"
                                  class="font-size-12 text-uppercase font-weight-600"
                                >
                                  Pre Quotation
                                </v-chip>
                              </div>
                              <div v-else-if="data.variation_level > 0">
                                <v-chip
                                  small
                                  label
                                  color="green white--text"
                                  class="font-size-12 text-uppercase font-weight-600"
                                >
                                  Pre Quotation
                                </v-chip>
                              </div>
                              <div v-else-if="data.parent_variation > 0">
                                <v-chip
                                  small
                                  label
                                  color="orange white--text"
                                  class="font-size-12 text-uppercase font-weight-600"
                                >
                                  Variation
                                </v-chip>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <div class="quotation-listing-customer">
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Quotation Title: </b
                                  >{{ data.quotation_title }}
                                </p>
                                <p
                                  class="m-0 custom-nowrap-ellipsis-two-line"
                                  v-if="data.billing"
                                >
                                  <b>Site Address: </b>
                                  <template v-if="data.billing.property_address"
                                    >{{ data.billing.property_address }},
                                  </template>
                                </p>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <div class="quotation-listing-customer">
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Reference: </b>
                                  <template v-if="data.reference">{{
                                    data.reference
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Reference</em
                                    ></template
                                  >
                                </p>
                                <p
                                  v-if="false"
                                  class="m-0 custom-nowrap-ellipsis"
                                >
                                  <b>Sales representative: </b>
                                  <template v-if="data.sales">{{
                                    data.sales
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Sales representative</em
                                    ></template
                                  >
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Prepared by: </b>
                                  <template v-if="data.prepared_by">{{
                                    data.prepared_by
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Prepared by</em
                                    ></template
                                  >
                                </p>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.date"
                              >
                                <b>Created: </b> {{ formatDate(data.date) }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.open_till"
                              >
                                <b>Open Till: </b>
                                {{ formatDate(data.open_till) }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.sent_date"
                              >
                                <b>Sent: </b> {{ formatDate(data.sent_date) }}
                              </p>
                            </td>
                            <td class="simple-table-td">
                              <CustomStatus
                                small
                                :status="data.status"
                                endpoint="quotation/status"
                              ></CustomStatus>
                            </td>
                            <td class="simple-table-td">
                              <div class="quotation-listing-amount">
                                <p
                                  v-if="false"
                                  class="m-0 custom-nowrap-ellipsis"
                                >
                                  <b>Profit: </b>
                                  <!--  {{
                                    $accountingJS.formatMoneyTwoDecimal(
                                      data.total_profit
                                    )
                                  }} -->
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Total: </b>

                                  {{ formatMoneyTwoDecimal(data.total) }}
                                </p>
                              </div>
                            </td>
                            <td class="simple-table-td">
                              <TableActivity
                                v-if="!lodash.isEmpty(data.added_by)"
                                :data="data"
                              >
                                <template v-slot:display_name>
                                  {{ data.added_by.display_name }}
                                </template>
                                <template v-slot:date_time>
                                  {{ data.created_at }}
                                </template>
                                <template v-slot:format_date_time>
                                  {{ formatDateTime(data.added_at) }}
                                </template>
                              </TableActivity>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-container>
              </v-tab-item>
              <template v-if="getPermission('history:view')">
                <v-tab-item value="history">
                  <InternalHistoryDetail
                    type="quotation"
                    is-package
                    :type_id="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <Dialog :common-dialog="delete_dialog">
        <template v-slot:title> Delete Confirmation </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              Are you sure, you want to delete file <b>{{ delete_file.name }}</b
              >?
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="delete_loading"
            :loading="delete_loading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="delete_file_confirm()"
          >
            Yes! Delete
          </v-btn>
          <v-btn
            :disabled="delete_loading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="delete_dialog = false"
          >
            No! Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="action_dialog">
        <template v-slot:title>
          <template v-if="action_type == 'Regenerate PDF'">{{
            action_type
          }}</template>
          <template v-else>
            Mark as <template v-if="action_type == 'Reject'">Rejected</template>
            <template v-else-if="action_type == 'Cancel'">Cancelled</template>
          </template>
        </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              Are you sure, you want to {{ action_type }}
              {{ get_page_title() }} <b>{{ action_barcode }}</b
              >?
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="action_loading"
            :loading="action_loading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="do_quote_action()"
          >
            Yes! {{ action_type }}
          </v-btn>
          <v-btn
            :disabled="action_loading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="action_dialog = false"
          >
            No! Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="salesSignatureDialog">
        <template v-slot:title> Signature </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              <div
                id="signature-pad"
                ref="sales_signature_div"
                class="custom-border-grey-dashed"
              >
                <canvas ref="sales_signature"></canvas>
              </div>
            </v-col>
            <v-col md="10" class="py-0" offset-md="1">
              <p class="float-right m-0">
                <v-btn
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  class="float-right"
                  v-on:click="sales_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="validSignature || signatureLoading || pageLoading"
            :loading="signatureLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="submitSalesSignature"
          >
            Submit
          </v-btn>
          <v-btn
            :disabled="signatureLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="salesSignatureDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="accept_dialog" dense>
        <template v-slot:title> Mark As Accepted </template>
        <template v-slot:body>
          <v-form
            ref="accepted_form"
            v-model="accepted_valid"
            lazy-validation
            v-on:submit.stop.prevent="update_accepted_file()"
          >
            <v-row class="mx-2 my-2">
              <v-col md="10" class="py-0" offset-md="1">
                <br />
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <v-icon large>mdi-information-outline</v-icon> Are you sure,
                  you want to mark as accepted?
                </p>
                <br />
                <v-layout>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="m-0 p-0"
                      v-model="has_attachment"
                      color="cyan"
                      :true-value="1"
                      :false-value="0"
                      label="Has Attachment?"
                      v-on:change="init_attachment()"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md6>
                    <v-checkbox
                      v-if="isPackage"
                      hide-details
                      class="m-0 p-0"
                      v-model="generate_case_trust"
                      color="cyan"
                      :true-value="1"
                      :false-value="0"
                      label="Generate Case Trust?"
                    >
                    </v-checkbox>
                  </v-flex>
                </v-layout>
                <br />
                <table width="100%" v-if="has_attachment">
                  <tbody>
                    <tr v-for="(row, index) in quote_attachments" :key="index">
                      <td width="50%" class="px-2 py-2">
                        <v-file-input
                          prepend-inner-icon="mdi-attachment mdi-rotate-90"
                          placeholder="Click here to select file"
                          label="Click here to select file"
                          prepend-icon=""
                          color="cyan"
                          solo
                          flat
                          v-model="row.accepted_file"
                          dense
                          v-on:change="update_file_name(index)"
                          :rules="[
                            validateRules.file_required(
                              row.accepted_file,
                              'File'
                            ),
                          ]"
                          hide-details
                        ></v-file-input>
                      </td>
                      <td width="50%" class="px-2 py-2">
                        <v-text-field
                          placeholder="File Name"
                          label="File Name"
                          dense
                          solo
                          flat
                          color="cyan"
                          v-model="row.file_name"
                          hide-details
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="remove_row(index)"
                          :suffix="get_file_extension(row.file_type)"
                          :rules="[
                            validateRules.required(row.file_name, 'File Name'),
                          ]"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td height="10px" colspan="2"></td>
                    </tr>
                    <tr v-if="quote_attachments.length < 5">
                      <td colspan="2" class="px-2">
                        <v-btn
                          color="cyan white--text"
                          small
                          v-on:click="add_attachment_row()"
                          class="custom-bold-button"
                          >Add More...</v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td height="10px" colspan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="update_accepted_file()"
            :disabled="!accepted_valid || accepted_loading"
            :loading="accepted_loading"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="accept_dialog = false"
            :disabled="accepted_loading"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="upload_dialog" dense>
        <template v-slot:title> Upload Document </template>
        <template v-slot:body>
          <div class="px-4">
            <v-form
              ref="upload_form"
              v-model="upload_valid"
              lazy-validation
              v-on:submit.stop.prevent="submit_documents()"
            >
              <table width="100%">
                <tbody>
                  <tr v-for="(row, index) in upload_documents" :key="index">
                    <td width="50%" class="px-2 py-2">
                      <v-file-input
                        prepend-inner-icon="mdi-attachment mdi-rotate-90"
                        placeholder="Click here to select file"
                        label="Click here to select file"
                        prepend-icon=""
                        color="cyan"
                        solo
                        flat
                        v-model="row.upload_file"
                        dense
                        v-on:change="update_upload_name(index)"
                        :rules="[
                          validateRules.file_required(row.upload_file, 'File'),
                        ]"
                        hide-details
                      ></v-file-input>
                    </td>
                    <td width="50%" class="px-2 py-2">
                      <v-text-field
                        placeholder="File Name"
                        label="File Name"
                        dense
                        solo
                        flat
                        color="cyan"
                        v-model="row.file_name"
                        hide-details
                        class="has-delete-outer-icon"
                        append-outer-icon="mdi-delete"
                        v-on:click:append-outer="remove_upload_row(index)"
                        :suffix="get_file_extension(row.file_type)"
                        :rules="[
                          validateRules.required(row.file_name, 'File Name'),
                        ]"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td height="10px" colspan="2"></td>
                  </tr>
                  <tr v-if="upload_documents.length < 5">
                    <td colspan="2" class="px-2">
                      <v-btn
                        color="cyan white--text"
                        small
                        :disabled="upload_loading"
                        v-on:click="add_upload_row()"
                        class="custom-bold-button"
                        >Add More...</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td height="10px" colspan="2"></td>
                  </tr>
                </tbody>
              </table>
            </v-form>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="submit_documents()"
            :disabled="!upload_valid || upload_loading"
            :loading="upload_loading"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="upload_dialog = false"
            :disabled="upload_loading"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog
        :common-dialog="pdf_view_dialog"
        dense
        :dialog-width="dialogWidth"
      >
        <template v-slot:title>
          <v-layout>
            <v-flex md6>
              {{ pdf_view_name }}
            </v-flex>
            <v-flex md6 class="text-right">
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="pdf_view_signed_dialog = true"
              >
                Upload Signed Copy
              </v-btn>
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="
                  pdf_view_src = null;
                  pdf_view_dialog = false;
                "
              >
                Close
              </v-btn>
            </v-flex>
          </v-layout>
        </template>
        <template v-slot:body>
          <div v-if="pdf_view_src">
            <pdf-viewer
              :src="pdf_view_src"
              :page="pdf_view_page"
              v-on:num-pages="pdf_view_count = $event"
              v-on:page-loaded="pdf_view_page = $event"
            ></pdf-viewer>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            v-on:click="pdf_view_page_prev()"
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            outlined
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
          >
            {{ pdf_view_page }} / {{ pdf_view_count }}
          </v-btn>
          <v-btn
            v-on:click="pdf_view_page_next()"
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="pdf_view_signed_dialog" dense>
        <template v-slot:title> Upload Signed {{ pdf_view_name }} </template>
        <template v-slot:body>
          <div class="px-4">
            <v-form
              ref="upload_signed_form"
              v-model="upload_signed_valid"
              lazy-validation
              v-on:submit.stop.prevent="submit_pdf_signed_copy()"
            >
              <table width="100%">
                <tr>
                  <td width="50%" class="px-2 py-2">
                    <v-file-input
                      prepend-inner-icon="mdi-attachment mdi-rotate-90"
                      placeholder="Click here to select file"
                      label="Click here to select file"
                      prepend-icon=""
                      color="cyan"
                      solo
                      flat
                      v-model="pdf_signed_copy_file"
                      dense
                      v-on:change="update_signed_name()"
                      :rules="[
                        validateRules.file_required(
                          pdf_signed_copy_file,
                          'File'
                        ),
                      ]"
                      hide-details
                      :disabled="pdf_signed_copy_loading"
                      :loading="pdf_signed_copy_loading"
                    ></v-file-input>
                  </td>
                  <td width="50%" class="px-2 py-2">
                    <v-text-field
                      placeholder="File Name"
                      label="File Name"
                      dense
                      solo
                      flat
                      color="cyan"
                      v-model="pdf_signed_copy_name"
                      hide-details
                      :suffix="get_file_extension(pdf_signed_copy_type)"
                      :rules="[
                        validateRules.required(
                          pdf_signed_copy_name,
                          'File Name'
                        ),
                      ]"
                      :disabled="pdf_signed_copy_loading"
                      :loading="pdf_signed_copy_loading"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-form>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            :disabled="!upload_signed_valid || pdf_signed_copy_loading"
            :loading="pdf_signed_copy_loading"
            v-on:click="submit_pdf_signed_copy()"
          >
            Upload
          </v-btn>
          <v-btn
            :disabled="pdf_signed_copy_loading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="pdf_view_signed_dialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="merge_dialog" dense :dialog-width="dialogWidth">
        <template v-slot:title> Merge PDF </template>
        <template v-slot:body>
          <div class="px-4 my-3">
            <v-form
              ref="merge_form"
              v-model="merge_form_valid"
              lazy-validation
              v-on:submit.stop.prevent="merge_pdf_submit(0)"
            >
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <th width="50%" class="py-2 px-2">
                    <label class="my-0 py-0">Document Name</label>
                  </th>
                  <th width="30%" class="py-2 px-4">
                    <label class="my-0 py-0">Merge Document</label>
                  </th>
                  <th width="20%" class="py-2 px-2">
                    <label class="my-0 py-0">Sequence</label>
                  </th>
                </tr>
                <tr v-for="(file, index) in file_selected" :key="index">
                  <td width="50%" class="py-2">
                    <v-text-field
                      placeholder="Document Name"
                      label="Document Name"
                      dense
                      solo
                      flat
                      color="cyan"
                      v-model="file.name"
                      readonly
                      hide-details
                      :disabled="merge_pdf_loading"
                      :loading="merge_pdf_loading"
                    ></v-text-field>
                  </td>
                  <td width="30%" class="py-2 px-4">
                    <v-radio-group
                      dense
                      class="mx-0 px-0"
                      row
                      v-model="file.merge_key"
                      :disabled="merge_pdf_loading || !file.signed_path"
                    >
                      <v-radio
                        color="cyan"
                        label="Raw"
                        value="raw_path"
                      ></v-radio>
                      <v-radio
                        color="cyan"
                        label="Signed"
                        value="signed_path"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td width="20%" class="py-2">
                    <v-text-field
                      :id="`sequence-${file.id}`"
                      placeholder="Sequence"
                      dense
                      solo
                      flat
                      color="cyan"
                      v-model="file.sequence"
                      hide-details
                      :disabled="merge_pdf_loading"
                      :loading="merge_pdf_loading"
                      :rules="[
                        validateRules.required(
                          file.sequence,
                          `Sequence ${file.name}`
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" width="100%" height="20px"></td>
                </tr>
                <tr>
                  <td colspan="3" width="100%" class="py-2">
                    <div class="d-flex align-center justify-center">
                      <label
                        class="my-0 py-0 pl-2 mr-2 font-weight-600"
                        for="merged-pdf-document-name"
                        >Merged PDF Document Name</label
                      >
                      <v-text-field
                        id="merged-pdf-document-name"
                        placeholder="Enter Merged PDF Document Name"
                        dense
                        solo
                        flat
                        color="cyan"
                        hide-details
                        class="ml-2"
                        v-model="merge_pdf_name"
                        :disabled="merge_pdf_loading"
                        :loading="merge_pdf_loading"
                        :rules="[
                          validateRules.required(
                            merge_pdf_name,
                            'Merged PDF Document Name'
                          ),
                        ]"
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
              </table>
            </v-form>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            :disabled="!merge_form_valid || merge_pdf_loading"
            :loading="merge_pdf_loading"
            v-on:click="merge_pdf_submit(1)"
          >
            Preview
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            :disabled="!merge_form_valid || merge_pdf_loading"
            :loading="merge_pdf_loading"
            v-on:click="merge_pdf_submit(0)"
          >
            Submit
          </v-btn>
          <v-btn
            :disabled="merge_pdf_loading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="merge_dialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <upload-case-trust-dialog
        :quotation-id="quotation"
        :case-trust-dialog="upload_case_trust_dialog"
        v-on:close="upload_case_trust_dialog = false"
        v-on:success="
          upload_case_trust_dialog = false;
          loadContent();
        "
      ></upload-case-trust-dialog>
      <case-trust-dialog
        quotation-detail
        :form-loading-c-t="case_trust_loading"
        :case-trust-dialog="case_trust_dialog"
        v-model="case_trust"
        v-on:close="case_trust_dialog = false"
        v-on:quote-case-trust="update_case_trust()"
        v-on:start-loading="case_trust_loading = true"
        v-on:stop-loading="case_trust_loading = false"
      ></case-trust-dialog>
      <send-mail
        :mail-dialog="mail_dialog"
        :title="`Send ${get_page_title()} Mail`"
        type="quotation"
        :type-id="quotation"
        v-on:close="mail_dialog = false"
        v-on:success="loadContent()"
      ></send-mail>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customerId"
          v-on:closeDialog="customerPropertyDialog = false"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailQuotationMixin from "@/core/lib/quotation/detail.quotation.mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CaseTrustDialog from "@/view/pages/quotation/Case-Trust-Dialog.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import {
  PATCH,
  DELETE,
  /*GET,*/ POST,
} from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemPackageDetail from "@/view/pages/partials/Line-Item-Package-Detail.vue";
import LineItemNewDetailV1 from "@/view/pages/partials/Line-Item-New-DetailV1.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import UploadCaseTrustDialog from "@/view/pages/quotation/Upload-Case-Trust-Dialog.vue";
import SendMail from "@/view/pages/partials/Send-Mail.vue";
import RelatedDocument from "@/view/pages/partials/Related-Document.vue";
import PdfViewer from "vue-pdf";
import ObjectPath from "object-path";
// import Base64 from "file-base64";

export default {
  mixins: [DetailQuotationMixin],
  components: {
    Dialog,
    PdfViewer,
    Barcode,
    TableActivity,
    UpdateTooltip,
    DetailTemplate,
    CustomStatus,
    CaseTrustDialog,
    SendMail,
    RelatedDocument,
    CustomerPropertyDialog,
    InternalHistoryDetail,
    LineItemPackageDetail,
    LineItemNewDetailV1,
    UploadCaseTrustDialog,
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSortClass: ".tab-container",
      quote_files: [],
      related_files: [],
      file_checkbox: [],
      file_selected: [],
      pdf_view: {},
      merge_pdf_name: null,
      pdf_view_src: null,
      pdf_view_name: null,
      pdf_view_count: 1,
      pdf_view_page: 1,
      pdf_signed_copy_name: null,
      pdf_signed_copy_file: null,
      pdf_signed_copy_type: null,
      pdf_signed_copy_loading: false,
      merge_pdf_loading: false,
      merge_dialog: false,
      mail_dialog: false,
      pdf_view_dialog: false,
      merge_pdf_valid: true,
      upload_signed_valid: true,
      pdf_view_signed_dialog: false,
      pdf_view_loaded: false,
      upload_valid: true,
      delete_loading: false,
      delete_dialog: false,
      delete_file: {},
      merge_preview: false,
      action_dialog: false,
      action_id: null,
      action_type: null,
      action_barcode: null,
      action_loading: false,
      generate_case_trust: 0,
      case_trust_dialog: false,
      case_trust_loading: false,
      accepted_valid: true,
      has_attachment: 0,
      accepted_loading: false,
      accept_dialog: false,
      upload_dialog: false,
      upload_loading: false,
      merge_form_valid: true,
      project: {},
      case_trust: {},
      attachments: [],
      quote_attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      upload_documents: [
        {
          upload_file: null,
          file_name: null,
          file_type: null,
        },
      ],
    };
  },
  methods: {
    open_merge_dialog() {
      const selected_files = [];

      const file_name = [];

      for (let i = 0; i < this.file_checkbox.length; i++) {
        const file = this.lodash.find(this.related_files, {
          id: this.file_checkbox[i],
          is_pdf: true,
        });
        if (file) {
          file.sequence = i + 1;
          file.merge_key = "raw_path";
          selected_files.push(file);
          file_name.push(file.name);
        }
      }

      if (selected_files.length < 2) {
        ErrorEventBus.$emit(
          "update:error",
          "Please select atleast 2 PDF to merge."
        );
        return false;
      }

      this.merge_pdf_name = file_name.join(" ");
      this.file_selected = selected_files;
      this.merge_dialog = true;
    },
    merge_pdf_submit(preview) {
      if (this.file_selected.length < 2) {
        ErrorEventBus.$emit(
          "update:error",
          "Please select atleast 2 PDF to merge."
        );
        return false;
      }

      const validateStatus = this.$refs.merge_form.validate();

      const formErrors = this.validateForm(this.$refs.merge_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      this.merge_pdf_loading = true;

      this.$store
        .dispatch(POST, {
          url: "quotation/merge-document",
          data: {
            preview: preview,
            "package-id": this.quotation,
            "file-name": this.merge_pdf_name,
            "file-selected": this.file_selected,
          },
        })
        .then(({ data }) => {
          if (preview) {
            window.open(data.pdf_url, "_blank");
          } else {
            this.merge_pdf_name = null;
            this.file_selected = [];
            this.merge_dialog = false;
            this.get_related_pdf();
          }
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.merge_pdf_loading = false;
        });
    },
    get_related_type(type) {
      if (type == 1) {
        return "Package Sales";
      }
      if (type == 2) {
        return "CaseTrust";
      }
      if (type == 3) {
        return "Quotation";
      }
      if (type == 4) {
        return "Other";
      }
    },
    update_signed_name() {
      const file = this.pdf_signed_copy_file;
      if (file) {
        this.pdf_signed_copy_name = file.name.split(".").slice(0, -1).join(".");
        this.pdf_signed_copy_type = file.name.split(".").pop();
      }
    },
    pdf_view_page_prev() {
      if (this.pdf_view_page > 1) {
        this.pdf_view_page = this.pdf_view_page - 1;
      }
    },
    pdf_view_page_next() {
      if (this.pdf_view_page < this.pdf_view_count) {
        this.pdf_view_page = this.pdf_view_page + 1;
      }
    },
    async submit_pdf_signed_copy() {
      const validateStatus = this.$refs.upload_signed_form.validate();

      const formErrors = this.validateForm(this.$refs.upload_signed_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      const request = {
        upload_file: await this.convert_base_64(this.pdf_signed_copy_file),
        file_name: this.pdf_signed_copy_name,
        file_type: this.pdf_signed_copy_type,
      };

      this.pdf_signed_copy_loading = true;

      this.$store
        .dispatch(PATCH, {
          url: `quotation/signed-document`,
          data: {
            "package-id": this.quotation,
            file: request,
            related_document: this.pdf_view.id,
          },
        })
        .then(({ data }) => {
          this.related_files = data;
          this.pdf_view_dialog = false;
          this.pdf_view_loaded = false;
          this.pdf_view_signed_dialog = false;
          this.pdf_view_src = null;
          this.pdf_view_name = null;
          this.pdf_view = {};
          this.pdf_signed_copy_file = null;
          this.pdf_signed_copy_name = null;
          this.pdf_signed_copy_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pdf_signed_copy_loading = false;
        });
    },
    download_related_pdf(row, type) {
      const url = ObjectPath.get(row, type);
      if (url) {
        window.open(url, "_blank");
      }
    },
    upload_related_pdf(row) {
      this.pdf_view_signed_dialog = true;
      this.pdf_view_name = row.name;
      this.pdf_view = row;
    },
    view_related_pdf(row) {
      this.pdf_view_dialog = true;
      this.pdf_view_loaded = false;
      this.pdf_view_src = row.raw_path;
      this.pdf_view_name = row.name;
      this.pdf_view = row;
      this.pdf_signed_copy_file = null;
      this.pdf_signed_copy_name = null;
      this.pdf_signed_copy_type = null;
    },
    get_related_pdf() {
      /*this.$store
        .dispatch(GET, {
          url: `quotation/related-documents?package-id=${this.quotation}`,
        })
        .then(({ data }) => {
          this.related_files = data;
        })
        .catch((error) => {
          this.logError(error);
        });*/
    },
    async submit_documents() {
      const validateStatus = this.$refs.upload_form.validate();

      const formErrors = this.validateForm(this.$refs.upload_form);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      const files = [];

      for (var i = 0; i < this.upload_documents.length; i++) {
        files.push({
          upload_file: await this.convert_base_64(
            this.upload_documents[i].upload_file
          ),
          file_name: this.upload_documents[i].file_name,
          file_type: this.upload_documents[i].file_type,
        });
      }

      this.upload_loading = true;

      this.$store
        .dispatch(PATCH, {
          url: `quotation/${this.quotation}/related-documents`,
          data: { files },
        })
        .then(() => {
          this.get_related_pdf();
          this.upload_dialog = false;
          this.upload_documents = [
            {
              upload_file: null,
              file_name: null,
              file_type: null,
            },
          ];
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.upload_loading = false;
        });
    },
    push_to_package() {
      this.$router.push(
        this.getDefaultRoute("package.sales.detail", {
          params: { id: this.quote_package.id },
        })
      );
    },
    delete_file_confirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: "file-manager/delete/" + this.delete_file.id,
        })
        .then(() => {
          this.delete_dialog = false;
          const index = this.lodash.findIndex(this.attachments, {
            id: this.delete_file.id,
          });
          if (index >= 0) {
            this.attachments.splice(index, 1);
          }
          this.$nextTick(() => {
            this.delete_file = {};
          });
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    delete_file_dialog(row) {
      this.delete_dialog = true;
      this.delete_file = row;
    },
    download_file(row) {
      if (row.url) {
        window.open(row.url, "_blank");
      }
    },
    get_page_title() {
      if (this.isPackage) {
        return "Package";
      } else {
        return "Summary Report";
      }
    },
    update_case_trust() {
      this.case_trust_loading = true;
      this.$store
        .dispatch(PATCH, {
          url: `quotation/${this.quotation}/case-trust`,
          data: { case_trust: this.case_trust },
        })
        .then(() => {
          this.case_trust_dialog = false;
          this.loadContent();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.case_trust_loading = false;
        });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    init_attachment() {
      this.quote_attachments = [];
      this.$nextTick(() => {
        this.add_attachment_row();
      });
    },
    update_file_name(index) {
      const file = this.quote_attachments[index].accepted_file;
      if (file) {
        this.quote_attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.quote_attachments[index].file_type = file.name.split(".").pop();
      }
    },
    update_upload_name(index) {
      const file = this.upload_documents[index].upload_file;
      if (file) {
        this.upload_documents[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.upload_documents[index].file_type = file.name.split(".").pop();
      }
    },
    async update_accepted_file() {
      const validateStatus = this.$refs.accepted_form.validate();

      const formErrors = this.validateForm(this.$refs.accepted_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (this.has_attachment) {
        const accepted_file = this.lodash.filter(
          this.lodash.map(this.quote_attachments, "accepted_file")
        );
        if (accepted_file.length < 1) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please select atleast one file.")
          );
          return false;
        }
      }

      const result = [];

      for await (const row of this.quote_attachments) {
        if (row.file_name) {
          result.push({
            accepted_file: await this.convert_base_64(row.accepted_file),
            file_name: row.file_name,
            file_type: row.file_type,
          });
        }
      }

      this.accepted_loading = true;

      this.$store
        .dispatch(PATCH, {
          url: `quotation/${this.quotation}/mark-as-accepted`,
          data: { file: result, has_attachment: this.has_attachment },
        })
        .then(() => {
          this.loadContent();
          this.accept_dialog = false;
          if (this.generate_case_trust == 1) {
            this.case_trust_dialog = true;
          }
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.accepted_loading = false;
        });
    },
    remove_row(index) {
      this.quote_attachments.splice(index, 1);
      this.$nextTick(() => {
        if (!this.quote_attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.quote_attachments.push({
        accepted_file: null,
        file_name: null,
      });
    },
    remove_upload_row(index) {
      this.upload_documents.splice(index, 1);
      this.$nextTick(() => {
        if (!this.upload_documents.length) {
          this.add_upload_row();
        }
      });
    },
    add_upload_row() {
      this.upload_documents.push({
        accepted_file: null,
        file_name: null,
      });
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
